

















































































































































































































import Base from '@/mixins/Base.vue';
import { IBlogPost, IBlogPostResponse } from '@/interfaces/blogpost';

const component = Base.extend({
    beforeRouteUpdate(to: any, from: any, next: Function) {
        this.blogPost = undefined;
        this.getBlogPost(to.params.id);
        next();
    },
    data() {
        return {
            currentLanguage: 'en',
            apiKey: process.env.VUE_APP_TINY_MCE_API_KEY,
            blogPost: undefined as any | IBlogPost,
        };
    },

    mounted() {
        this.getBlogPost(parseInt(this.$route.params.id, 0));
    },
    methods: {
        getBlogPost(id: number) : void{
            this.get<IBlogPostResponse>(`blog/posts/${id}`, {
                params: {
                    similars: true,
                },
            }).then(({ data }) => {
                this.blogPost = data.data;
            });
        },
    },
});

export default component;
